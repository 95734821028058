import React from 'react'
import '../components/css/layout.css'
import Header from '../components/Header'
import Footer from '../components/Footer'
import '../components/css/policy.css'
import digiPadalaAgent from 'images/digipadala-agent.png'
import { Image } from 'react-bootstrap'
import Seo from '../components/Seo'
const DigiPadalaAgent = () => (
  <div>
    <Seo
      title="Digipay PH | DigiPadalaAgent"
      description="Digipay is a digital payments and financial services platform with
a mobile wallet, an industry leading biller ecosystem,
and an interoperable backend."
      keywords="digipay.ph/DigiPadalaAgent/,DigiPadalaAgent, DigiPadala, Agent, Requirements, Customer, Customer Requirements, Money Transfer Guide."
    />
    <Header />
    <div id="digipadala-agent-section">
      <Image src={digiPadalaAgent} alt="digipadala-agent" width="100%" />
    </div>
    <Footer></Footer>
  </div>
)
export default DigiPadalaAgent
